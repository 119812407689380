<template>
  <div class="customer-profiling">
    <router-view />
  </div>
</template>

<script>
import { onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    onBeforeUnmount(() => {
      store.dispatch('betTicker/clearAllCustomers');
    });
  },
};
</script>

<style lang="scss">
.customer-profiling {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
